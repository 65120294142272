import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
    "*": {
        fontFamily: "'Source Code Pro', serif !important",
    },
    "h1 > *, h1": {
        fontFamily: "Inconsolata, serif !important",
    },
    "code *": {
        fontFamily: "Inconsolata, serif !important",
    }
  }
}

delete Wordpress2016.googleFonts


console.log(Wordpress2016)
const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
